import React from 'react';
import ReactDOM from 'react-dom';
import { loadableReady } from '@loadable/component';
import { ScreenRecognitionProvider } from '@phx-husky/screen-recognition';

import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/600.css';
import '@fontsource/montserrat/700.css';

const handleGatsbyAnnouncer = () => {
  const gatsbyAnnouncer = document.querySelector('#gatsby-announcer');
  if (gatsbyAnnouncer) {
    gatsbyAnnouncer.setAttribute('role', 'navigation');
  }
};

export const replaceHydrateFunction = () => (element, container, callback) => {
  loadableReady(() => {
    ReactDOM.hydrate(element, container, callback);
  });
};

export const onInitialClientRender = () => {
  handleGatsbyAnnouncer();
};

export const wrapRootElement = ({ element }) => (
  <ScreenRecognitionProvider>{element}</ScreenRecognitionProvider>
);
